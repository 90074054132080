@import url(https://fonts.googleapis.com/css?family=Raleway);
body {
  margin: 0;
  font-family: Raleway !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a.view-map {
  font-family: Raleway !important;
}
p.map-description {
  font-family: Raleway !important;
}
.dx-loadpanel-message {
  font-family: Raleway !important;
}