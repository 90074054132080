.lg-backdrop {
    background-color: rgba(0, 0, 0, 0.5) !important;
}
.lg-img-wrap {
    width: 80% !important;
    height: 80% !important;
    margin: auto !important;

    img.lg-image {
        border: 1px solid #fff;
        margin-top: -37px;
    }
}
.lg-toolbar {
    background-color: rgba(0, 0, 0, 0.8) !important;

    .lg-close {
        margin-right: unset !important;
    }

    .lg-fullscreen {
        margin-right: 43% !important;
    }

    .lg-zoom-in {
        margin-right: 50% !important;
        margin-top: -47% !important
    }

    .lg-actual-size {
        margin-right: 45.6% !important;
        margin-top: -47.7% !important;
    }
}

.lg-actions button.lg-icon {
    color: #fff !important;
    font-size: 32px !important;
    cursor: pointer;

    @media screen and (max-width: 500px) {
        display: none;
    }

}
.gallery-thumb {
    display: flex;
    flex-wrap: wrap;

    .column {
        flex: 33.33%;
        padding: 5px;
        max-width: 265px;

        img {
            border: 1px solid #ddd; /* Gray border */
            border-radius: 4px;  /* Rounded border */
            padding: 10px; /* Some padding */
            max-width: 250px;
            max-height: 150px;
            cursor: pointer;
        }

        /* Add a hover effect (blue shadow) */
        img:hover {
            box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
        }
    }
}

@media screen and (max-width: 500px) {
    .gallery-thumb {
        .column {
            width: 33.33%;
        }
    }
}

