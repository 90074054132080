.mapboxgl-ctrl-attrib-inner a {
   color: #b52b27;
}

.mapboxgl-popup-content {
   text-align: center;
   font-family: Raleway !important;
}
.marker {
   background-size: cover;
   width: 37px;
   height: 37px;
   cursor: pointer;
}

.map-description {
   font-size: 11px;
}
.mapboxgl-popup {
   width: 120px !important;
}

.mapboxgl-popup-close-button:focus {
   outline: none;
   box-shadow: none;
}

.view-map {
   cursor: pointer;
   font-size: 13px;

   :hover {
      text-decoration: underline;
   }
}

.dx-popup-title.dx-toolbar {
   padding: 6px 20px;
   overflow: visible;
   -webkit-box-shadow: none;
   box-shadow: none;
}

.dx-popup-content p {
   margin-bottom: 10px;
   margin-top: 0;
}

.dx-popup-wrapper .dx-toolbar {
   background-color: #4d4d4d;
   color: #fff;
   padding: 10px !important;
   height: 48px;
}

.dx-closebutton {
   margin: -4px -4px -0 -4px !important;
}

i.dx-icon-close {
   color: #fff !important;
}

.dx-popup-title.dx-toolbar .dx-toolbar-label {
   padding: 0 10px 10px 10px !important;
   max-width: unset !important;
}

.dx-popup-content .dx-field-label {
   float: right;
   padding-left: 10px;
   margin-bottom: 10px;
}

.dx-popup-content > .dx-button {
   margin-left: auto;
   margin-right: auto;
   display: block;
}

.dx-check-icon {
   background-color: #f9f9f9;
}

.dx-overlay-wrapper.welcome-popup {
   font-family: Raleway !important;

   .dx-toolbar {
      font-family: Raleway !important;
   }

   .dx-field-label {
      font-family: Raleway !important;
   }

   .dx-button-text {
      font-family: Raleway !important;
   }
}
