.client-selection {
  .dx-popup-bottom {
    background-color: #fff;
  }

  .dx-list-item {
    border: 1px solid #e8e8e8;
  }

  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected,
  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused,
  .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected.dx-state-hover:not(.dx-state-focused)
  {
    background-color: #5f8fb4;
    color: #fff;
  }

  .dx-toolbar-after {
    position: absolute;
    left: 21%;
    top: -10px;
  }

  .dx-scrollview {
    margin-top: 6px;
  }
}