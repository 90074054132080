@import url(https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.2.0/mapbox-gl-draw.css);
@import url(https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.7.0/mapbox-gl-geocoder.css);

@font-face {
  font-family: 'Virtual-Tours-Marker-Icons';
  src:  url('https://kw-vt.s3.us-west-1.amazonaws.com/assets/fonts/Virtual-Tours-Marker-Icons.eot?erjml0');
  src:  url('https://kw-vt.s3.us-west-1.amazonaws.com/assets/fonts/Virtual-Tours-Marker-Icons.eot?erjml0#iefix') format('embedded-opentype'),
  url('https://kw-vt.s3.us-west-1.amazonaws.com/assets/fonts/Virtual-Tours-Marker-Icons.ttf?erjml0') format('truetype'),
  url('https://kw-vt.s3.us-west-1.amazonaws.com/assets/fonts/Virtual-Tours-Marker-Icons.woff?erjml0') format('woff'),
  url('https://kw-vt.s3.us-west-1.amazonaws.com/assets/fonts/Virtual-Tours-Marker-Icons.svg?erjml0#Virtual-Tours-Marker-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="vtm-icon-"], [class*=" vtm-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Virtual-Tours-Marker-Icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.layers-icon {
  margin-bottom: 40px;
  margin-top: 20px;
  margin-left: 4px;

  img {
    z-index: 1;
    position: absolute;
    top: 20px;
    left: 10px;
    cursor: pointer;
    width: 50px;
    /*opacity: .6 !important;*/

    @media screen and (max-width: 640px) {
      top: 5px;
    }
  }
}

.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.sidebarStyle {
  display: inline-block;
  position: absolute;
  top: 55px;
  left: 0;
  margin: 10px 10px 10px 0;
  max-height: 40px;
  min-width: 205px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;

  .dx-field-label {
    color: #fff;
    margin-top: -5px;
    width: unset !important;
  }

  .dx-field-value {
     width: unset !important;
  }

  .dx-checkbox {
     opacity: 0.5;
  }
  .dx-checkbox.dx-checkbox-checked {
     opacity: 1;
  }
}

.sidebarStyle.unchecked {
  opacity: 0.4;
}

.mapboxgl-ctrl-attrib-inner a {
  color: #b52b27;
}

#menu {
  background: #fff;
  position: absolute;
  z-index: 1;
  top: 106px;
  left: 0;
  /*border-radius: 3px;*/
  width: 305px;
  /*height: 87%;*/
  overflow-x: hidden;
  overflow-y: auto;
  /*border: 1px solid rgba(0, 0, 0, 0.4);*/
  font-family: Raleway !important;

  --scrollbarBG: #CFD8DC;
  --thumbBG: #90A4AE;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);

  .dx-field-value {
    float: left !important;
    width: 100%;
    display: grid;
    text-align: center;
  }

  .dx-checkbox {
    color: #b1b1ba;
    font-weight: 300;
    /*font-family: "Segoe UI Light", "Helvetica Neue Light", "Segoe UI", "Helvetica Neue", "Trebuchet MS", Verdana;*/
    font-family: Raleway !important;
  }

  .dx-checkbox.dx-checkbox-checked {
    color: #333;
    font-weight: 400;
    font-size: 14px;
  }

  .dx-checkbox-text {
    text-align: left !important;
  }
}

#menu::-webkit-scrollbar {
  width: 11px;
}

#menu::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

#menu::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

#lnglatzoom-display {
  display: none;
  top: 0;
  left: 45%;
}

#spotelevation-display {
  display: none;
  top: 0;
  left: 30%;
  background-color: #fff;
  color: #000;
  font-weight: bolder;

  @media screen and (max-width: 1068px) {
    left: 20%;
  }
  @media screen and (max-width: 879px) {
    left: 30%;
    top: 60px;
    max-width: 100px;
    min-height: 100px;
  }
}

.map-toolbar {
  position: absolute !important;
  top: 5px;
  z-index: 5;
  width: 500px;
  left: 40%;
  height: 45px;
  opacity: 0.9;

  .dx-toolbar-items-container {
    margin-top: -5px;
  }
}

.distance-container {
  position: absolute;
  top: 10px;
  left: 43.3%;
  z-index: 1;

  @media screen and (max-width: 850px) {
    left: 20%;
  }

  @media screen and (max-width: 748px) {
    top: 120px
  }

  @media screen and (max-width: 370px) {
    top: 120px;
  }
}

.distance-container > * {
  background-color: #fff;
  color: #000;
  /*font-family: 'Helvetica Neue','Segoe UI',Helvetica,Verdana,sans-serif;*/
  font-family: Raleway !important;
  font-size: 22px;
  font-weight: bolder;
  line-height: 1.5;
  display: block;
  margin: 0;
  padding: 5px 10px;
  border-radius: 3px;
  z-index: 1;
}
.calculation-box {
  height: 75px;
  width: 150px;
  position: absolute;
  top: 250px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  text-align: center;
  display: none;
}

p {
  font-family: Raleway !important;
  margin: 0;
  font-size: 13px;
}

circle {
  stroke: #fff;
  stroke-width: 1;
}
svg text.icon {
  font-family: 'Virtual-Tours-Marker-Icons';
  font-size: 20px;
  fill: #fff;
}

.circle-icon {
  cursor: pointer;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: Raleway !important;
  color: #ac9999;

  h3 {
     font-size: 13px;
  }
}
.dx-popup-wrapper {
  z-index: 1003 !important;
}
.dx-overlay-shader {
  background-color: rgba(255,255,255,.1);
}
/*.dx-popup-normal {
  max-width: 1300px;
  width: unset !important;
  height: unset !important;
  margin-top: 10%;
  margin-left: 10%;
}*/
/*.dx-popup-content {
  max-height: unset !important;
  min-height: unset !important;
  height: unset !important;
}*/

/* Gallery Popup */
.react_lightgallery_item {

  img {
    /*-webkit-transition: -webkit-transform 0.15s ease 0s;
    -moz-transition: -moz-transform 0.15s ease 0s;
    -o-transition: -o-transform 0.15s ease 0s;
    transition: transform 0.15s ease 0s;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);*/
    max-width: 100%;
    display: block;
  }
}
/*.react_lightgallery_item:hover img {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
  cursor: zoom-in;
}*/
.video-popup .dx-popup-content {
  div:first-child {
    width: 100% !important;
    height: 100% !important;
  }
}
.dx-popup-content .content {
  /*column-count: 4;*/
  /*column-gap: 10px;*/
  /*max-height: 500px !important;
  max-width: 1290px !important;*/

  .container {

    display: block !important;

    figure {
      margin: 0;
      display: grid;
      grid-template-rows: 1fr auto;
      margin-bottom: 10px;
      break-inside: avoid;
    }

    figure > img {
      grid-row: 1 / -1;
      grid-column: 1;
    }

    figure a {
      color: black;
      text-decoration: none;
    }

    figcaption {
      grid-row: 2;
      grid-column: 1;
      background-color: rgba(255, 255, 255, .5);
      padding: .2em .5em;
      justify-self: start;
    }
  }
}

#scale-label {
  font-family: Raleway !important;
  margin-left: -30px;
  font-weight: bold;
  margin-bottom: -30px;
}

.mapboxgl-ctrl-top-center {
  top: 0;
  left: 50%;
  position:absolute;
  pointer-events:none;
  z-index:2;
}

.mapboxgl-ctrl-top-center .mapboxgl-ctrl {
  margin:10px 0 0 10px;
}

.mapboxgl-ctrl-bottom-center {
  bottom: 17px !important;
  left: 50%;
  position:absolute;
  pointer-events:none;
  z-index:2;

  @media screen and (max-width: 1120px) {
    bottom: 20px !important;
    left: 33.3333333%
  }
  @media screen and (max-width: 482px) {
    bottom: 50px !important;
  }

  .mapboxgl-ctrl-scale {
    font-family: Raleway !important;
  }
}

.mapboxgl-ctrl-bottom-center.print-view {
  bottom: 70px !important;
}

.mapboxgl-ctrl-bottom-center .mapboxgl-ctrl {
  margin:10px 0 40px 10px;
}

#stylemenu {
  position: absolute;
  background: #efefef;
  padding: 2px;
  font-family: Raleway !important;
  right: 0;
  bottom: 30px;

  @media screen and (max-width: 482px) {
    bottom: 47px;
    font-size: 12px;
  }

  label {
    margin-right: 10px;
  }
}

.mapboxgl-ctrl-geocoder {

  @media screen and (max-width: 640px) {
    position: absolute;
    right: 100%;
    top: 0;
    width: 33.3333%;
    font-size: 15px;
    line-height: 20px;
    min-width: 180px;

    .mapboxgl-ctrl-geocoder--icon-search {
      top: 8px;
      left: 7px;
      width: 20px;
      height: 20px;
    }

    .mapboxgl-ctrl-geocoder--input {
      height: 36px;
      padding: 6px 35px;
    }
  }
}
.mapbox-gl-draw_trash {
  display: none !important;
}

.kw-comment-marker {
  width: 35px;
  height: 55px;
  cursor: pointer;

  img {
    width: 35px;
    height: 55px;
  }
}

#comments-field {
  .dx-first-row {
    width: 100% !important;
  }
  .dx-field-item-label, .dx-field-item-content {
    margin-left: 12px;
  }
}
#coordinates-field {
  margin-top: 0;
  margin-left: 12px;
}
.polygon-colorpicker {
  max-width: 245px;
  /*margin-left: auto;*/
  margin-left: 59.25px;
  margin-right: auto;
  margin-top: 15px;

  .dx-field-item-label {
    margin-bottom: 10px;
  }
}
.new-thread-popup .button-container {
  display: inline-flex;
  margin-left: 90px;
  margin-right: auto;
  margin-top: 50px;


  .dx-button {
    margin-right: 10px;
  }
}
.footer-title-block {
  bottom: 0;
  width: 100%;
  position:absolute;
  pointer-events:none;
  z-index:2;
  height: 55px;

  .mapboxgl-title-attrib {
    height: 55px;
    background-color: #f4f3eb !important;
    opacity: 0.9;
  }
}

.vt-title, .vt-attribution {
  font-family: Raleway !important;
}

.vt-attribution {
  margin-top: -12px;
}

.mb-attribution {
  font-size: 9px;
  position: absolute;
  right: 0;
  font-family: Raleway !important;
}

.dx-popup-wrapper {
  font-family: Raleway !important;

  .dx-item-content, .dx-button-text, .dx-texteditor-input {
    font-family: Raleway !important;
  }

}

.sidenav {
  font-family: Raleway !important;

  .dx-treeview {
    font-family: Raleway !important;
  }
}

.custom-height-slider {
  margin-left: 70px;
}

.dx-field .dx-slider {
  flex: 1;
}

.dx-slider-handle {
  background-color: #5b6770;
}

.dx-slider .dx-state-focused.dx-slider-handle:not(.dx-state-active),
.dx-slider .dx-state-hover.dx-slider-handle:not(.dx-state-active) {
  background-color: #5b6770
}

#detach-btn-container {
  position: absolute;
  top: 6px;
  right: 40px;
}

.dx-icon-detach {
  background-image: url('https://kw-vt.s3.us-west-1.amazonaws.com/assets/images/detach.png');
  background-repeat: no-repeat;
  background-position: 0px 0px;
}

.dx-datagrid-rowsview .dx-select-checkboxes-hidden > tbody > tr > td > .dx-select-checkbox {
  display: inline-block !important;
}

.dx-button-has-text .dx-button-content {
  font-weight: bold;
}

.dx-button-mode-contained.dx-button-success {
  background-color: #D6001C;
}
.dx-button-mode-contained.dx-button-success:hover {
  background-color: #f2001c;
}
.dx-button-mode-contained.dx-button-success:focus {
  background-color: #f2001c;
}
.gallery-popup .dx-toolbar-after,.video-popup .dx-toolbar-after,.page-popup .dx-toolbar-after {
  margin-top: -2px;
}
.dx-icon-share {
  font: 12px/1 DXIcons !important;
  margin-left: -2px;
  margin-right: 2px;
}
.cm-coordinates-container {
  display: flex !important;

  .dx-texteditor-input-container {
    width: 165px;
  }

  .cm-coordinates-separator {
    margin-top: 15px;
    font-weight: bold;

    span {
      margin-left: 11px;
      margin-right: 11px;
    }
  }
}