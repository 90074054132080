.user-comments * {
  box-sizing: border-box;
}
.user-comments {
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";*/
  font-family: Raleway !important;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.85);
  /*background-color: #f9f9f9;*/

  button {
    font-family: Raleway !important;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    font-size: 14px;
    padding: 4px 8px;
    color: rgba(0, 0, 0, 0.85);
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    font-weight: bold;
  }

  button:hover,
  button:focus,
  button:active {
    cursor: pointer;
    background-color: #ecf0f1;
  }

  .reply-btn {
    padding: 0 8px 4px 8px;
  }

  .comment-thread {
    width: 700px;
    max-width: 100%;
    margin: auto;
    padding: 0 14px;
    background-color: #fff;
    border: 1px solid transparent; /* Removes margin collapse */
  }

  .m-0 {
    margin: 0;
  }

  .sr-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  /* Comment */

  .comment {
    position: relative;
    margin: 0 auto;
  }

  .comment-heading {
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 14px;
  }

  .comment-voting {
    width: 20px;
    height: 32px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  .comment-info {
    color: rgba(0, 0, 0, 0.5);
    margin-left: 10px;
  }

  .comment-author {
    color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
    text-decoration: none;
  }

  .comment-author:hover {
    text-decoration: underline;
  }

  .replies {
    margin-top: 10px;
    margin-left: 20px;
  }

  #comment-1 {
    margin-bottom: 30px;
  }

  #comments-scroll {
    margin-top: 30px;
  }

  /* Adjustments for the comment border links */

  .comment-border-link {
    display: block;
    position: absolute;
    top: 50px;
    left: 0;
    width: 12px;
    height: calc(100% - 50px);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    background-color: rgba(0, 0, 0, 0.1);
    background-clip: padding-box;
  }

  .comment-border-link:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .comment-body {
    padding: 0 20px;
    padding-left: 28px;
  }

  .replies {
    margin-left: 28px;
  }

  /* Adjustments for toggleable comments */
  details.comment {
    -webkit-transition: background 1s;
    -moz-transition: background 1s;
    -o-transition: background 1s;
    transition: background 1s
  }

  details.comment summary {
    position: relative;
    list-style: none;
    cursor: pointer;
  }

  details.comment summary::-webkit-details-marker {
    display: none;
  }

  details.comment .comment-heading {
    img {
      width: 38px;
      margin-left: -14px;
      margin-top: -10px;
    }
  }

  details.comment:not([open]) .comment-heading {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .comment-heading::after {
    display: inline-block;
    position: absolute;
    right: 5px;
    align-self: center;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.55);
  }

  details.comment[open] .comment-heading::after {
    /*content: "Click to hide";*/
  }

  details.comment:not([open]) .comment-heading::after {
    /*content: "Click to show";*/
  }

  .dx-state-disabled.dx-widget {
    opacity: 1;
  }

  /* Adjustment for Internet Explorer */

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* Resets cursor, and removes prompt text on Internet Explorer */
    .comment-heading {
      cursor: default;
    }
    details.comment[open] .comment-heading::after,
    details.comment:not([open]) .comment-heading::after {
      content: " ";
    }
  }

  /* Styling the reply to comment form */

  .reply-form textarea {
    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";*/
    font-family: Raleway !important;
    font-size: 16px;
    width: 100%;
    max-width: 100%;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .reply-form button {
    margin-right: 5px;
  }

  .d-none {
    display: none;
  }

  .tox-statusbar__branding {
    display: none !important;
  }

  .spacer-30 {
    height: 30px;
    display: none;
  }

  .spacer-30.active {
    display: block;
  }

  .dx-icon-reply {
    background-image: url('https://kw-vt.s3.us-west-1.amazonaws.com/assets/images/reply.svg');
    background-repeat: no-repeat;
    background-position: 0px 0px;
    width: 18px;
    height: 18px;
    margin-right: 4px;
    margin-left: -4px;
    margin-bottom: -1.5px;
  }

  .dx-icon-pending {
    background-image: url('https://kw-vt.s3.us-west-1.amazonaws.com/assets/images/button-pending.gif');
    background-repeat: no-repeat;
    background-position: 0px 0px;
    width: 18px;
    height: 18px;
    margin-right: 4px;
    margin-left: -4px;
  }

  .comment-body button.comment-submit {
    max-height: 69.2834px
  }
  .comment-body button:disabled {
    background-color: #ecf0f1;
  }
}