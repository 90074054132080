.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 50px;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav .closebtn {
  position: absolute;
  top: 43%;
  right: 0;
  font-size: 36px;
  margin-left: 50px;
  cursor: pointer;

  i {
    font-size: 27px;
  }
}

.sidenav .menu-title {
  margin-top: -40px;
  margin-left: 50px;
  color: #535353;
  font-size: 21px;
}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}