.custom-height-slider {
    .dx-field-label {
      float: unset;
      padding-left: 0;
      margin-bottom: 0;
    }

    .slider-output {
      margin-top: -30px;
      margin-left: 280px;
    }
}

.new-thread-popup .dx-popup-normal .dx-popup-content .thread-subscriptions {
  left: 0;
  margin-top: -45px;
  margin-left: -25px;
}

.new-thread-popup .dx-popup-title.dx-toolbar .dx-toolbar-after {
  margin-top: -3px;
}