.confirm-popup {
  .dx-popup-title {
    background-color: #d5d5d5 !important;
  }
  .dx-toolbar {
    background-color: #fff;
    color: #4d4d4d;
    padding: 10px !important;
    height: 48px;
  }
  i.dx-icon-close {
    color: #000 !important;
  }
}