.user-comments .dx-popup-title.dx-toolbar .dx-toolbar-after {
  margin-top: -3px;
}
.user-comments .dx-toolbar {
  background-color: #fff;
  color: #4d4d4d;
  padding: 10px !important;
  height: 48px;
}
.user-comments i.dx-icon-close {
  color: #000 !important;
}
.user-comments .thread-toolbar {
  position: absolute;
  left: 0;
  top: 30px;

  .dx-field-label {
    float: left !important;
    text-overflow: unset !important;
    padding-left: 0 !important;
    margin-left: -7px;
  }
}
.dx-dropdowneditor-overlay .dx-overlay-content {
  min-width: 400px;
}

#thread-subscriptions {
  position: absolute;
  /*left: 15px;*/
  width: 100%;
  margin-left: -121px;
  .dx-field-value {
    min-width: 160px;
    .dx-dropdowneditor {
      margin-left: -17px;
    }
    .dx-texteditor-empty {
      /*margin-left: 0;*/
    }
  }
}
.user-comments .dx-overlay-content .dx-popup-content .thread-subscriptions {
  margin-left: -150px !important;
  width: 400px !important;
}
#thread-subscription {
  position: absolute;
  left: -35px;

  .dx-field-label {
    position: absolute;
    top: 0;
    left: 60px;
    overflow: visible;
  }
  .dx-field-value {
    position: absolute;
    left: 160px;
  }
}

#thread-status {
  position: absolute;
  /*top: 68px;*/
  left: 275px;
  .dx-field-value {
    margin-left: -30px;
    .dx-dropdownbutton {
      .dx-buttongroup {
        min-width: 100px;
        margin-left: -32px;
      }
    }
    .dx-dropdowneditor {
      margin-left: 10px;
    }
  }
}
#thread-status.align-left {
  left: 170px !important;
}