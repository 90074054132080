.dashboard-container {
  position: relative;
  /*margin-top: 30px;*/
  margin-bottom: 50px;
  font-family: Raleway !important;
}

.dashboard-container .dx-widget {
  font-family: Raleway !important;
}

.dashboard-container .dx-treeview-item {
  color: #efefef;
}

.dashboard-container .dx-treeview-item.dx-state-hover {
  background-color: #5f8fb4;
}

.dashboard-container .dx-treeview-item-without-checkbox.dx-state-selected > .dx-treeview-item {
  color: #efefef;
  background-color: #5f8fb4;
}

.dashboard-container .left-content{
  background-color: #5b6770;
}
.container,
.left-content {
  min-height: 530px;
}

.left-content {
  position: fixed;
  width: 180px;
  height: 100%;
  padding: 30px 10px 10px 10px;
  background-color: rgba(191, 191, 191, 0.15);
  /*box-shadow: -5px 0 14px -8px rgba(0, 0, 0, 0.25) inset;*/
}

.right-content {
  margin-left: 220px;
  height: 100%;
  padding-top: 30px;
}

sup {
  font-size: 0.8em;
  vertical-align: super;
  line-height: 0;
}

.right-content .sub-title {
  font-size: 120%;
  color: rgba(152, 152, 152, 0.8);
}

.title-container {
  min-height: 140px;
  margin-bottom: 10px;
}

.title-container .map-name {
  font-size: 240%;
  font-weight: bold;
  line-height: 34px;
  margin-bottom: 10px;
}

.stats {
  display: table;
  width: 100%;
  margin-bottom: 20px;
}

.stats > div {
  display: table-cell;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  padding: 20px 0 25px;
  width: 33%;
}

.stats > div:first-child,
.stats > div:last-child {
  border-right-width: 0;
  border-left-width: 0;
}

.stats .stat-value {
  font-size: 200%;
}

#tabpanel {
  margin-top: 10px;
}

#tabpanel .dx-multiview-wrapper {
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

#tabpanel .tab-panel-title {
  font-size: 120%;
  font-weight: 500;
}

#tabpanel .dx-multiview-item-content {
  padding: 20px 0 22px;
  min-height: 178px;
  position: relative;
}

#tabpanel .right-content {
  top: 15px;
  left: 202px;
}

#tabpanel .stats {
  width: 398px;
  margin-top: 20px;
  border-top: 1px solid rgba(191, 191, 191, 0.25);
}

#tabpanel .stats > div {
  padding: 7px 0;
  text-align: left;
  border: 0;
}

#tabpanel .stats > div:first-child {
  width: 40%;
}

#tabpanel .stats > div:not(:first-child) {
  width: 30%;
}

.flag {
  width: 172px;
  max-height: 122px;
  border: 1px solid rgba(191, 191, 191, 0.25);
  float: left;
  margin: 0 30px 10px 0;
}

.left-content .dx-treeview-node.dx-state-selected > .dx-item > .dx-item-content {
  font-weight: 500;
}

.left-content li.dx-treeview-node[aria-level="1"] .dx-treeview-item .dx-item-content,
.left-content li.dx-treeview-node.dx-state-selected[aria-level="1"] .dx-item > .dx-item-content
{
  font-weight: bold;
}

.left-content li.dx-treeview-node-is-leaf .dx-treeview-item .dx-item-content {
  font-weight: unset !important;
}

.left-content li.dx-treeview-node.dx-treeview-item-without-checkbox.dx-state-focused[aria-level="1"] > .dx-treeview-item,
.left-content li.dx-treeview-node.dx-treeview-item-without-checkbox[aria-level="1"] > .dx-treeview-item.dx-state-hover
{
  background-color: unset;
  font-weight: bold;
}

.map-preview {
  display: block;
  margin-top: 30px;
}

/* The overlay effect - lays on top of the container and over the image */
.map-overlay {
  position: absolute;
  background: #fff;
  width: 500px;
  bottom: 140px;
  padding: 12px;
  text-align: center;
}

.dashboard-map-link {
  cursor: pointer;
  width: 500px;
  display: block;
}

.dashboard-intro {
  font-size: 16px;
}

.dashboard-symbol {
  font-size: 20px;
}