/*
 * Hide high-res map rendering
 */
.hidden-map {
  overflow: hidden;
  height: 0;
  width: 0;
  position: fixed;
}

.print-form-container {
  padding: 50px;
  background-color: #fff;
}

.print-map-container {

  margin-top: -50px;

  .form-buttons {
    margin-left: -35px;
  }

  .dx-button-normal {
    margin-top: -73px;
    margin-left: 120%;
  }
}
.print-title-block {
  bottom: 0;
  width: 100%;
  position:absolute;
  pointer-events:none;
  z-index:2;
  height: 100px;

  .mapboxgl-title-attrib {
    height: 100px;
    background-color: #f4f3eb !important;
    opacity: 0.9;
  }
}
.title-attrib {
  height: 100px;
}
.vt-title {
  font-size: 20px;
  margin-left: 70px;
  margin-top: -12px;
}
.vt-attribution {
  float: right;
}
