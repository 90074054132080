.back-arrow {
  margin-bottom: 65px;
  margin-top: 20px;
  margin-left: 4px;

  img {
    z-index: 1;
    position: absolute;
    top: 20px;
    left: 10px;
    cursor: pointer;
    width: 30px;
  }
}

.options {
  padding: 20px;
  margin-top: 20px;
  background-color: rgba(191, 191, 191, 0.15);
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}