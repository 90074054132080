.side-nav-inner-toolbar {
  width: 100%;
}

.container {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.layout-body {
  flex: 1;
  min-height: 0;
}

.content {
  flex-grow: 1;
}

#navigation-header {
  @import "../../themes/generated/variables.additional.scss";
  background-color: $base-nav-bgcolor;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .menu-button .dx-icon {
    color: $base-text-color;
  }

  .screen-x-small & {
    padding-left: 20px;
  }
    
  .dx-theme-generic & {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .header-title {
    display: inline-flex;

    .dx-item-content {
      display: inherit;
      margin-left: 15px;

      .logo-text {
        font-size: 20px;
        margin-left: 5px;
        margin-top: 5px;
      }

      img {
        width: 32px;
        height: 32px;
      }
    }
  }
}
