/*
    Body, button, comment-thread, and utilities

    Notes:
        - This section sets some basic styles. You can ignore this part and
        go directly to the comment styles.
*/

* {
  box-sizing: border-box;
}
.user-threads {
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";*/
  font-family: Raleway !important;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.85);
  /*background-color: #f9f9f9;*/

  .dx-popup-title.dx-toolbar .dx-toolbar-after {
    margin-top: -3px;
  }
}
.user-threads .threads-toolbar {
  background-color: #fff;
  color: #4d4d4d;
  padding: 10px !important;
  height: 46px;
  margin-top: -27px;
  margin-left: -20px;

  .dx-toolbar-after {
    left: 0 !important;
    padding-left: 0;
    margin-top: 6px;
    margin-left: -5px;
    z-index: 300;

    .dx-icon-overflow {
      margin-top: -2px;
    }

    .dx-icon-overflow:before {
      content: '+';
      font-size: 24px;
      font-weight: bold;
    }

    .dx-dropdownmenu-button {
      height: 36px;
    }
  }
}
.user-threads button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: 14px;
  padding: 4px 8px;
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.user-threads button:hover,
.user-threads button:focus,
.user-threads button:active {
  cursor: pointer;
  background-color: #ecf0f1;
}
.user-threads .comment-thread {
  width: 700px;
  max-width: 100%;
  margin: auto;
  padding: 0 30px;
  background-color: #fff;
  border: 1px solid transparent; /* Removes margin collapse */
}
.user-threads .m-0 {
  position: absolute;
  top: 37px;
}
.user-threads .sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/* Comment */

.user-threads .comment {
  position: relative;
  margin: 20px auto;
}
.user-threads .comment-heading {
  display: flex;
  align-items: center;
  height: 50px;
  font-size: 14px;
}
.user-threads .comment-icon {
  width: 20px;
  height: 32px;

  img.marker-icon {
    width: 25px;
    height: 40px;
    margin-left: -5px;
    margin-top: -7px;
  }
  img.draw-icon {
    width: 30px;
    height: 30px;
    margin-left: -8px;
  }
}
.user-threads .comment-icon button {
  display: block;
  width: 100%;
  height: 50%;
  padding: 0;
  border: 0;
  font-size: 10px;
}

.user-threads .comment-info {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 10px;
  margin-top: 20px;
}
.user-threads .comment-author {
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  text-decoration: none;
}
.user-threads .comment-author:hover {
  text-decoration: underline;
}
.user-threads .replies {
  margin-left: 20px;
}

/* Adjustments for the comment border links */

.user-threads .comment-border-link {
  display: block;
  position: absolute;
  top: 50px;
  left: 0;
  width: 12px;
  height: calc(100% - 50px);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  background-color: rgba(0, 0, 0, 0.1);
  background-clip: padding-box;
}
.user-threads .comment-border-link:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.user-threads .comment-body {
  padding: 0 20px 30px;
  padding-left: 28px;
}
.user-threads .replies {
  margin-left: 28px;
}

/* Adjustments for toggleable comments */

.user-threads details.comment summary {
  position: relative;
  list-style: none;
  cursor: pointer;
}
.user-threads details.comment summary::-webkit-details-marker {
  display: none;
}
.user-threads .comment-body {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: -25px;
}
.user-threads .comment-heading::after {
  display: inline-block;
  position: absolute;
  right: 5px;
  align-self: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.55);
}
/*.user-threads details.comment[open] .comment-heading::after {
  content: "Click to hide";
}
.user-threads details.comment:not([open]) .comment-heading::after {
  content: "Click to show";
}*/

/* Adjustment for Internet Explorer */

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* Resets cursor, and removes prompt text on Internet Explorer */
  .user-threads .comment-heading {
    cursor: default;
  }
  .user-threads .comment .comment-heading::after,
  .user-threads details.comment:not([open]) .comment-heading::after {
    content: " ";
  }
}

/* Styling the reply to comment form */

.user-threads .reply-form textarea {
  font-family: Raleway !important;
  font-size: 16px;
  width: 100%;
  max-width: 100%;
  margin-top: 15px;
  margin-bottom: 5px;
}
.user-threads .d-none {
  display: none;
}

.dx-toolbar .dx-toolbar-item.btn-active .dx-item-content .dx-button {
  background-color: #c2c2c2 !important;
  border-color: #ddd !important;
  color: #333 !important;
}

.dx-icon-markertool {
  background-image: url('https://kw-vt.s3.us-west-1.amazonaws.com/assets/images/comments_marker_icon_black.png');
  background-repeat: no-repeat;
  background-position: 0px 0px;
}
.dx-icon-areatool {
  background-image: url('https://kw-vt.s3.us-west-1.amazonaws.com/assets/images/comments_draw_icon.png');
  background-repeat: no-repeat;
  background-position: 0px 0px;
}

.kw-comment-marker-placeholder {
  width: 35px;
  height: 55px;
  cursor: pointer;

  img {
    width: 35px;
    height: 55px;
  }
}

.user-threads .status-filter {
  margin: -30px 0 0 -5px !important;
  max-width: 100px;

  .dx-button-text {
    font-weight: bold;
    margin-left: 5px;
  }
  .dx-dropdownbutton .dx-button-has-text .dx-icon.dx-icon-right {
    margin-left: 10px;
    margin-right: 5px;
  }
  .dx-button-mode-contained.dx-buttongroup-last-item, .dx-button-mode-outlined.dx-buttongroup-last-item {
    padding-right: 5px;
  }
  .dx-dropdownbutton-action.dx-buttongroup-last-item.dx-button .dx-button-content {
    padding-left: 10px;
  }
}
.user-threads .thread-status {
  margin-top: -11px;
  margin-left: 60%;
}
.user-threads .delete-btn {
  margin-left: 132px;
  margin-bottom: 26px;
}

.user-threads .view-thread-btn {
  margin-right: 7px;
  font-weight: bold;

  .dx-icon-share {
    margin-right: 5px;
  }
}

.user-threads .share-thread-btn {
  font-weight: bold;
}

/*
.user-threads .add-thread-menu {
  .dx-menu-item {
    height: 40px;

    .dx-menu-item-content {
      margin-top: -10px;

      .dx-menu-item-text {
        font-size: 40px;
        margin-left: 12px;
      }

      .dx-menu-horizontal .dx-menu-item-popout:before {
        display: none;
      }
    }
  }
}*/
