@import "../../themes/generated/variables.base.scss";
@import "../../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button>.dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }

  .dx-toolbar .dx-toolbar-item.btn-active .dx-item-content .dx-button {
    background-color: #c2c2c2 !important;
    border-color: #ddd !important;
    color: #333 !important;
  }
  .header-toolbar {
    .dx-toolbar-items-container {
      .dx-toolbar-before {
        .logo-container {
          .dx-toolbar-item-content {
            .logo-text {
              display: block;

              @media screen and (max-width: 789px) {
                display: none;
              }
            }
          }
          @media screen and (max-width: 789px) {
            margin-left: -90px;
          }
        }
        .menu-button {
          .dx-toolbar-item-content {
            .dx-button-has-icon {
              @media screen and (max-width: 789px) {
                margin-left: 50px;
              }
            }
          }
        }
      }
      .dx-toolbar-center {
        .dx-toolbar-button {
          @media screen and (max-width: 449px) {
            position: relative;
            top: 0;
            left: 20px;
          }
          @media screen and (max-width: 400px) {
            padding: 0 3px 0 0 !important;
          }
          @media screen and (max-width: 357px) {
            top: 110px;
            left: 30px;
          }
        }
      }
      .dx-toolbar-after {
        .dx-icon-share {
          font: 16px/1 DXIcons !important;
        }
      }
    }
  }

  .logo-container {
    display: inline-flex;

    .dx-item-content {
      display: inherit;
      margin-left: 15px;

      .logo-text {
        font-size: 20px;
        margin-left: 10px;
        margin-top: 5px;
      }

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;

  .dx-toolbar-item-content {
    .dx-button {
      @media screen and (max-width: 399px) {
        margin-left: 40px !important;
      }
    }
  }
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button>.dx-button-content {
    padding: 3px;
  }
}


/* Icons */
.dx-icon-measuretool {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PHBhdGggc3R5bGU9Im1hcmtlcjpub25lIiBkPSJNMTMuNSAzLjVBMi41IDIuNSAwIDAwMTEgNmMwIC4zMi4wNDYuNjI0LjE1Ni45MDZsLTMuNzUgMy43NUEyLjQ2OCAyLjQ2OCAwIDAwNi41IDEwLjUgMi41IDIuNSAwIDEwOSAxM2MwLS4zMi0uMDQ2LS42MjQtLjE1Ni0uOTA2bDMuNzUtMy43NWMuMjgyLjExLjU4NS4xNTYuOTA2LjE1NmEyLjUgMi41IDAgMDAwLTV6IiBjb2xvcj0iIzAwMCIgb3ZlcmZsb3c9InZpc2libGUiLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: 0px 0px;
}
.dx-icon-polygonareatool {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cellipse cx='11.76' cy='13.79' rx='1.93' ry='2.04' transform='rotate(-45 11.758 13.78)'/%3E%3Cellipse cx='7.79' cy='2.21' rx='1.93' ry='2.04' transform='rotate(-45 7.796 2.21)' data-name='path4372'/%3E%3Cellipse cx='14.02' cy='6.79' rx='1.93' ry='2.04' transform='rotate(-45 14.015 6.795)' data-name='path4372'/%3E%3Cellipse cx='1.98' cy='6.79' rx='1.93' ry='2.04' transform='rotate(-45 1.982 6.789)' data-name='path4372'/%3E%3Cellipse cx='4.41' cy='13.79' rx='1.93' ry='2.04' transform='rotate(-45 4.413 13.792)' data-name='path4372'/%3E%3Cpath d='M10.11 14.18H5.9l-2.51-1.83-1.3-4 1-2.95 3.4-2.47h3.07L13 5.4l1 2.95-1.3 4zm-3.66-1.69h3.11l1.62-1.18 1-3-.62-1.91L9 4.62H7L4.49 6.44l-.62 1.91 1 3z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 0px 0px;
}
.dx-icon-spotelevationtool {
  background-image: url('https://kw-vt.s3.us-west-1.amazonaws.com/assets/images/spot-elevation-icon.png');
  background-repeat: no-repeat;
  background-position: 0px 0px;
}
.dx-icon-coordinatestool {
   background-image: url('https://kw-vt.s3.us-west-1.amazonaws.com/assets/images/coordinates-icon.png');
   background-repeat: no-repeat;
   background-position: 0px 0px;
 }
.dx-icon-contourstool {
  background-image: url('https://kw-vt.s3.us-west-1.amazonaws.com/assets/images/contours-icon.png');
  background-repeat: no-repeat;
  background-position: 0px 0px;
}
.dx-icon-3Dtool {
  background-image: url('https://kw-vt.s3.us-west-1.amazonaws.com/assets/images/3d-terrain-icon.png');
  background-repeat: no-repeat;
  background-position: 0px 0px;
}
.dx-icon-commentstool {
  background-image: url('https://kw-vt.s3.us-west-1.amazonaws.com/assets/images/comments-icon.png');
  background-repeat: no-repeat;
  background-position: 0px 0px;
}
.dx-icon-viewstool {
  background-image: url('https://kw-vt.s3.us-west-1.amazonaws.com/assets/images/views-icon.png');
  background-repeat: no-repeat;
  background-position: 0px 0px;
}
.dx-icon-markerstool {
  background-image: url('https://kw-vt.s3.us-west-1.amazonaws.com/assets/images/markers_icon.png');
  background-repeat: no-repeat;
  background-position: 0px 0px;
}
.btn-share-map {
  .dx-item-content {
    .dx-button {
      height: 35px;
      background-color: #d6001c;
      color: #fff;
      margin-right: 40px;

      @media screen and (max-width: 640px) {
        margin-right: -1px;
      }
    }
    .dx-button:hover {
      background-color: #992f2f;
    }
  }
}
.btn-sharemap.btn-share-mobile .dx-button-has-text {
    .dx-button-content {
      padding: 8px !important;
    }
}

.date-last-updated {
  font-family: Raleway !important;
  font-size: 14px;
  padding-right: 100px !important;

  @media screen and (max-width: 1725px) {
    display: none;
  }
}

#copy-map-url {
  margin-left: 20px;
}

header .dx-icon-share {
  color: #fff !important;
}

header .public-logo {
  margin-left: 10px;
}
.dx-popover-wrapper .dx-overlay-content {
  margin-top: 10px;
  border: 1px solid #000 !important;
}
.dx-popover-wrapper .dx-popover-arrow::after {
  border: 1px solid #000 !important;
}

.btn-mapbox-trash {
  display: none;
}
.btn-mapbox-trash.trash-visible {
  display: table-cell;
}

.share-popup .dx-toolbar-after {
  margin-top: -2px;
}
