.back-arrow {
  margin-bottom: 65px;
  margin-top: 20px;
  margin-left: 4px;

  img {
    z-index: 1;
    position: absolute;
    top: 20px;
    left: 10px;
    cursor: pointer;
    width: 30px;
  }
}

.form-container {
  padding: 50px;
  background-color: #fff;
}

.long-title h3 {
  font-family: Raleway, sans-serif;
  font-weight: 200;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}

.dx-form-group-caption {
  font-family: Raleway, sans-serif;
}

.dx-field-item-label-text {
  font-family: Raleway, sans-serif;
}

.dx-button-mode-contained {
  font-family: Raleway, sans-serif;
}

.dx-button-mode-contained.dx-button-default {
  background-color: #5f8fb4;
  font-family: Raleway, sans-serif;
}

.buttongroups-container {
  display: flex;
  justify-content: center;
}
.form-buttons {
  display: flex;
  justify-content: center;
  padding-top: 60px !important;
  margin-left: auto;
  z-index: 10;

  .dx-button-default {
    display: inline-block;
  }
  .dx-button-normal {
    margin-top: -79px;
    margin-left: 150%;
  }
}
.hidden-field {
  display: none !important;
}

.dx-invalid-message {
  width: unset !important;
}

.current-password {
  margin-top: 30px;
}

.settings-form input{
  font-family: Raleway, sans-serif;
}